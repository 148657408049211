import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_VENDORS_URL = `${API_URL}api/v1/auth/admin/vendor`
export const GET_SEARCH_VENDORS_URL = `${API_URL}api/v1/auth/admin/vendor`
export const CHANGE_STATUS_VENDOR_URL = `${API_URL}api/v1/auth/admin/vendor/status`
export const CHANGE_BLACKLIST_STATUS_VENDOR_URL = `${API_URL}api/v1/auth/admin/vendor/black-list`
export const CREATE_VENDOR_URL = `${API_URL}api/v1/auth/admin/vendor`
export const EDIT_VENDOR_URL = `${API_URL}api/v1/auth/admin/vendor`
export const DELETE_VENDOR_URL = `${API_URL}api/v1/auth/admin/vendor`
export const FETCH_API_STOCK_URL = `${API_URL}api/v1/auth/admin/vendor/fetch-api-stocks`


interface ListType {
  count?: any
  data: any
}

// Get USers Data
export async function getVendorsData(startIndex: number, limitNumber: number) {
    try {
      let vendorsData: ListType  = await axios.get(`${GET_VENDORS_URL}?skip=${startIndex}&limit=${limitNumber}`)
      return vendorsData
    } catch (e) {
      throw e
    }
  }

  export async function getSearchVendorsData(startIndex: number, limitNumber: number,searchText: string) {
    try {
      let vendorsData: ListType  = await axios.get(`${GET_SEARCH_VENDORS_URL}?skip=${startIndex}&limit=${limitNumber}&q=${searchText}`)
      return vendorsData
    } catch (e) {
      throw e
    }
  }  

  
  // Change Status User Data
export async function changeStatusVendorData(id: string, flag: boolean) {
  try {
    let vendorData = await axios.put(`${CHANGE_STATUS_VENDOR_URL}`, {
      id,
      is_active: flag,
    })
    return vendorData
  } catch (e) {
    throw e
  }
}

export async function changeBlackListData(id: string, flag: boolean) {
  try {
    let vendorData = await axios.put(`${CHANGE_BLACKLIST_STATUS_VENDOR_URL}`, {
      id,
      is_blacklisted: flag,
    })
    return vendorData
  } catch (e) {
    throw e
  }
}
// Create User Data
export async function createVendorData(vendorObject: any) {
  try {
    let createVendorData = await axios.post(`${CREATE_VENDOR_URL}`, {
      ...vendorObject,
    })
    return createVendorData
  } catch (e) {
    throw e
  }
}

// Edit User Data
export async function editVendorData(vendorObject: any) {
  try {
    let editVendorData = await axios.put(`${EDIT_VENDOR_URL}`, {
      ...vendorObject,
    })
    return editVendorData
  } catch (e) {
    throw e
  }
}

// Delete User Data
export async function deleteVendorData(id: string) {
  try {
    let deleteVendorData = await axios.delete(`${DELETE_VENDOR_URL}?id=${id}`)
    return deleteVendorData
  } catch (e) {
    throw e
  }
}

// Vendor Details and Terms Condition Data
export async function vendorDetailsAndTermsConditionData(vendorObject: any) {
  try {
    let editVendorData = await axios.put(`${EDIT_VENDOR_URL}`, {
      ...vendorObject,
    })
    return editVendorData
  } catch (e) {
    throw e
  }
}

export async function fetchVendorStockData(id:any) {
  try {
    let fetchStock  = await axios.get(`${FETCH_API_STOCK_URL}?id=${id}`)
    return fetchStock
  } catch (e) {
    throw e
  }
}
