import * as React from 'react'
import {Formik} from 'formik'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {changeVariantPriceApi, getVariantPriceApi} from '../redux/ChatsCRUD'

interface IRenderFormikForm {
  values: IFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IFormValues {
  variant_price: number
}

interface IEditProps {
  handleCancel?: any
  variantPrice?: any
  Id?: any
}

export class UpdateVariantPriceForm extends React.Component<IEditProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = async (values: IFormValues, action: any) => {
    const { Id, handleCancel} = this.props
    try {
      let variantData: any = {}
      variantData.id = Id
      
      if (values.variant_price) {
        variantData.variant_price = values.variant_price
      }
      let priceChange = await changeVariantPriceApi(variantData)

      if (priceChange && priceChange.status === 200) {
        Toaster({
          type: 'success',
          title: 'Variant Price',
          description: 'Updated Successfully',
        })
      }
    } catch (e) {
      console.log(e, 'error')
    } finally {
      handleCancel()
      this.setState({
        visible: false,
      })
    }
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {variantPrice} = this.props
    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{variant_price: variantPrice}}
              
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <input
                            type='number'
                            className='form-control'
                            value={values.variant_price || 0}
                            onChange={(event) =>
                              setFieldValue('variant_price', parseInt(event.target.value))
                            }
                          />
                        </div>
                      </div>
                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
