import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import { InputNumberText } from '../../../modules/comman/formComponents/InputNumberText'

interface IRenderFormikForm {
  values: IUserFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IUserFormValues {
  first_name: string
  last_name: string
  email: string
  vendor_type: string
  company_name: string
  api_url?: string
  margin_percentage?: number
}

interface IEditUserProps {
  onSubmit: any
  data: any
  handleCancel?: any
}

export class EditVendorForm extends React.Component<IEditUserProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
    }
  }

  public validateForm = (values: IUserFormValues) => {
    const errors: any = {}
    console.log('Validating form -- ', values)

    if (!values.first_name) {
      errors.first_name = 'First name is required'
    }

    if (!values.last_name) {
      errors.last_name = 'Last name is required'
    }

    if (!values.email) {
      errors.email = 'Email is required'
    }

    if (values.email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(String(values.email).toLowerCase())) {
      errors.email = 'Please Provide Valid Email'
    }
      
    if (!values.vendor_type) {
      errors.vendor_type = 'Vendor Type is required'
    }

    // if (values.vendor_type && values.vendor_type == "API" && !values.api_url) {
    //   errors.api_url = 'Api Url is required'
    // }

    if (!values.company_name) {
      errors.company_name = 'Company Name is required'
    }

    if (values.margin_percentage && values.margin_percentage < 0) {
      errors.margin_percentage = 'Margin must be Positive'
    }

    if (values.margin_percentage && values.margin_percentage > 100) {
      errors.margin_percentage = 'Margin must be less than 100'
    }

    console.log('Validating errors -- ', errors)
    return errors
  }

  public handleSubmit = (values: IUserFormValues, action: any) => {
    const {data, handleCancel, onSubmit} = this.props
    console.log('Basic Value - ', values)

    if (values.vendor_type && values.vendor_type !== 'API' && 'api_url' in values) {
      delete values.api_url
    }
    // values.phone_iso = values.phone.split('-').length > 0 ? values.phone.split('-')[0] : ''
    onSubmit(values, data?.id)
    handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {data} = this.props

    return (
      <div className='container'>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  first_name: data.first_name ? data.first_name : '',
                  last_name: data.last_name ? data.last_name : '',
                  email: data.email ? data.email : '',
                  vendor_type: data.vendor_type ? data.vendor_type : '',
                  api_url: data.api_url ? data.api_url : '',
                  id: data.id ? data.id : '',
                  company_name: data.company_name ? data.company_name : '',
                  margin_percentage: data.margin_percentage ? data.margin_percentage : '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.first_name,
                              id: 'first_name',
                              name: 'first_name',
                            }}
                            placeholder='Enter first name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('first_name', value)
                            }}
                            onBlur={handleBlur}
                            label='First Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.last_name,
                              id: 'last_name',
                              name: 'last_name',
                            }}
                            placeholder='Enter last name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('last_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Last Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        {/* <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.vendor_type,
                              id: 'vendor_type',
                              name: 'vendor_type',
                            }}
                            placeholder='Enter Vendor Type'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('vendor_type', value)
                            }}
                            onBlur={handleBlur}
                            label='Vendor Type'
                            error={errors}
                            touched={touched}
                          />
                        </div> */}
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.email,
                              id: 'email',
                              name: 'email',
                            }}
                            placeholder='Enter email'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('email', value)
                            }}
                            onBlur={handleBlur}
                            label='Email'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputSingleSelect
                            input={{
                              //   value: values.vendor_type,
                              id: 'vendor_type',
                              name: 'vendor_type',
                              value: values.vendor_type ? values.vendor_type : undefined,
                              options: [
                                {
                                  label: 'API',
                                  value: 'API',
                                },
                                {
                                  label: 'UPLOAD',
                                  value: 'UPLOAD',
                                },
                                {
                                  label: 'FTP',
                                  value: 'FTP',
                                },
                              ],
                            }}
                            placeholder='Select Stock Upload type'
                            onChangeMethod={(value: string) => {
                              setFieldValue('vendor_type', value)
                            }}
                            label='Stock Upload type'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.company_name,
                              id: 'company_name',
                              name: 'company_name',
                            }}
                            placeholder='Enter Company Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('company_name', value)
                            }}
                            onBlur={handleBlur}
                            label='Company Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.margin_percentage,
                              id: 'margin_percentage',
                              name: 'margin_percentage',
                            }}
                            placeholder='Enter Margin Percentage'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('margin_percentage', value)
                            }}
                            onBlur={handleBlur}
                            label='Margin (%)'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        {/* {values.vendor_type == 'API' && (
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.api_url,
                                id: 'api_url',
                                name: 'api_url',
                              }}
                              placeholder='Enter Api Url'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('api_url', value)
                              }}
                              onBlur={handleBlur}
                              label='Api Url'
                              error={errors}
                              touched={touched}
                            />
                          </div>
                        )} */}
                      </div>

                      {values.vendor_type == 'API' && (
                                              <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.api_url,
                                id: 'api_url',
                                name: 'api_url',
                              }}
                              placeholder='Enter Api Url'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('api_url', value)
                              }}
                              onBlur={handleBlur}
                              label='Api Url'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          </div>
                        )} 
                      <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
