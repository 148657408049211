import React from 'react'
import axios from 'axios'
import {config} from '../../../comman/constants'

// const CHAT_API_URL = 'http://192.168.29.28:3002/'
const CHAT_API_URL = config.chatConfigUrl || 'http://localhost:2806/'
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

interface audioResponse {
  file: string
  status: number
  message: string
}

export const GET_CHAT_USER_LIST_URL = `${CHAT_API_URL}api/v1/auth/admin/chat-rooms`
export const UPLOAD_AUDIO_FROM_CHAT_URL = `${CHAT_API_URL}file/upload/audio/file`
export const EDIT_VARIANT_PRICE_URL = `${API_URL}api/v1/auth/admin/update-inquiry`
export const GET_VARIANT_PRICE_URL = `${API_URL}api/v1/auth/admin/inquiry-details`

export async function getChatUserListData() {
  try {
    let userData = await axios.get(`${GET_CHAT_USER_LIST_URL}`)
    return userData
  } catch (e) {
    throw e
  }
}

export async function uploadChatAudio(formData: any) {
  try {
    let audioUploadResponse: audioResponse = await axios.post(
      `${UPLOAD_AUDIO_FROM_CHAT_URL}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return audioUploadResponse
  } catch (e) {
    throw e
  }
}

export async function changeVariantPriceApi(values: any) {
  console.log({values55: values})
  let editData = await axios.put(`${EDIT_VARIANT_PRICE_URL}`, {
    ...values,
  })
  return editData
}

export async function getVariantPriceApi(roomId: any) {
  console.log({roomId5: roomId})
  let getPriceData = await axios.get(`${GET_VARIANT_PRICE_URL}?id=${roomId}`)
  return getPriceData
}
