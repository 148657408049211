import React, {useEffect, useState} from 'react'
import {config} from '../../../comman/constants'
import {fetchVendorStockData} from '../redux/VendorsCRUD'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'
import {Spin} from 'antd'
import ReactJson from 'react-json-view'

let host = config.host
let port = '21'

const FtpDetails = (props: any) => {
  const [getStockRes, setStockRes] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(getStockRes, null, 2))
    setCopied(true)
    // alert('Data copied to clipboard!')
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }
  useEffect(() => {
    console.log({props})

    const callFetchApiStockApi = async (id: any) => {
      try {
        let fetchApiRes: any = await fetchVendorStockData(id)
        console.log({fetchApiRes})
        console.log({fetchApiRes: fetchApiRes.data})

        setStockRes(fetchApiRes.data || '')
        // if (fetchApiRes && fetchApiRes.status === 200) {
        //   Toaster({
        //     type: 'success',
        //     title: 'VENDORS',
        //     description: fetchApiRes.data.message || fetchApiRes['message'],
        //   })
        // } else {
        //   Toaster({
        //     type: 'error',
        //     title: 'VENDORS',
        //     description: fetchApiRes.data.message,
        //   })
        // }
        setIsLoading(false)
      } catch (e: any) {
        console.log('!!!!Error', e, e.message)
        setStockRes(e.message)
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    }
    if (props.data.id && props.data.type && props.data.type === 'fetchApi') {
      callFetchApiStockApi(props.data.id)
    }
  }, [])

  return (
    <>
      {props.data.type && props.data.type === 'fetchApi' ? (
        <div className='d-flex flex-row-reverse justify-content-around mx-auto'>
          <div
            className='d-flex align-items-center mb-3'
            style={{height: 'fit-content', width: '15%'}}
          >
            {/* <h5>JSON Data</h5> */}
            <button style={{width: '100%'}} onClick={copyToClipboard} className='btn btn-primary'>
              {copied ? 'Copied !' : 'Copy JSON'}
            </button>
          </div>{' '}
          <div className='row' style={{width: '65%', overflow: 'auto', height: '225px'}}>
            <div className='row' style={{marginBottom: '20px'}}>
              {/* <div
              className='col-sm-6 col-12'
              style={{
                fontWeight: 600,
                letterSpacing: '0.5px',
                color: '#000',
              }}
            >
              Fetch Stock Details Api Response:
            </div> */}
              <div className='col-sm-6 col-12' style={{width: '75%'}}>
                {' '}
                {isLoading ? (
                  <Spin />
                ) : getStockRes ? (
                  typeof getStockRes == 'object' ? ( //<p>{JSON.stringify(getStockRes,null,2)}</p>
                    <ReactJson
                      src={getStockRes}
                      // theme="monokai" // Optional: Choose a theme
                      collapsed={1} // Collapse levels by default
                      enableClipboard={true} // Enable copying JSON values
                      displayDataTypes={false} // Hide data types
                      displayObjectSize={true} // Show size of objects/arrays
                    />
                  ) : (
                    // Object.entries(getStockRes).map(([key, value]) => (
                    //   <div style={{display: 'flex'}}>
                    //     <strong>{key}:</strong>
                    //     <div style={{paddingLeft: '1em'}}>
                    //       {typeof value === 'object' && value !== null
                    //         ? value
                    //         : getStockRes[key]?.toString() ?? 'N/A'}
                    //     </div>
                    //   </div>
                    // ))
                    getStockRes
                  )
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='row'>
          <div className='col-md-6 col-12'>
            <div className='row' style={{marginBottom: '20px'}}>
              <div
                className='col-sm-6 col-12'
                style={{
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  color: '#000',
                }}
              >
                FTP Username :
              </div>
              <div className='col-sm-6 col-12' style={{}}>
                {props.data && props.data.ftp_username ? props.data.ftp_username : '-'}
              </div>
            </div>
            <div className='row' style={{marginBottom: '20px'}}>
              <div
                className='col-sm-6 col-12'
                style={{
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  color: '#000',
                }}
              >
                Host :
              </div>
              <div className='col-sm-6 col-12' style={{}}>
                {' '}
                {host ? host : ''}
              </div>
            </div>
          </div>
          <div className='col-md-6 col-12'>
            <div className='row' style={{marginBottom: '20px'}}>
              <div
                className='col-sm-6 col-12'
                style={{
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  color: '#000',
                }}
              >
                FTP Password :
              </div>
              <div className='col-sm-6 col-12' style={{}}>
                {' '}
                {props.data && props.data.ftp_password ? props.data.ftp_password : '-'}
              </div>
            </div>
            <div className='row' style={{marginBottom: '20px'}}>
              <div
                className='col-sm-6 col-12'
                style={{
                  fontWeight: 600,
                  letterSpacing: '0.5px',
                  color: '#000',
                }}
              >
                Port :
              </div>
              <div className='col-sm-6 col-12' style={{}}>
                {' '}
                {port ? port : ''}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default FtpDetails
