import * as React from 'react'
import {Formik} from 'formik'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {connect} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'

interface IRenderFormikForm {
  values: ISearchFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface ISearchFormValues {
  shape: any
  size: any
  color: any
  clarity: any
  finish: any
  fluorescence_intensity: any
  grading_report: any
}

interface IEditInventoryProps {
  onSubmit: any
  onClearSearch: any
  searchParamsData: any
  searchTextData: any
  setSearchParamsData: (searchParams: any) => void
  setSearchTextData: (searchTextData: any) => void
}

type PathParamsType = {
  param1: string
}

class ListSearchForm extends React.Component<
  RouteComponentProps<PathParamsType> & IEditInventoryProps,
  any
> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      roleData: [],
      searchParams: {
        color_type: 'white',
      },
      colorType: 'white',
      sizeInputSearchVal: [],
      expanded: true,
      isSearched: false,
      color_type: 'white',
      searchText: '',
    }
  }

  public handleExpandClick = () => {
    this.setState({expanded: !this.state.expanded})
  }

  public validateForm = (values: ISearchFormValues) => {
    const errors: any = {}

    const {searchParams} = this.state

    if (this.sizeSearchType === 'input' && searchParams['size'] && searchParams['size'][0]) {
      let getSizeInputValue = searchParams['size'][0]
      let sizeInputArray = getSizeInputValue.split('-')
      if (!sizeInputArray[0]) {
        errors.from = 'Please enter from value'
      }
      if (!sizeInputArray[1]) {
        errors.to = 'Please enter to value'
      }
      if (sizeInputArray.length >= 2) {
        if (sizeInputArray[0] === sizeInputArray[1]) {
          errors.to = 'Please enter the different values'
        } else if (parseInt(sizeInputArray[0]) > parseInt(sizeInputArray[1])) {
          errors.to = 'To value should greater then From'
        } else if (parseInt(sizeInputArray[1]) < parseInt(sizeInputArray[0])) {
          errors.to = 'From value should lesser then To'
        }
      }
    }

    return errors
  }

  public handleSubmit = (values: ISearchFormValues, action: any) => {
    const {onSubmit} = this.props
    const {searchParams} = this.state
    this.props.setSearchParamsData(searchParams)
    onSubmit(searchParams, this.state.searchText)
    this.setState({isSearched: true}) //set the variable true to log the search action called
    this.scrollToTheTableElement()
  }

  public getTotalOffsetTop = (element: HTMLElement): number => {
    let offsetTop = 0
    while (element) {
      offsetTop += element.offsetTop
      element = element.offsetParent as HTMLElement
    }
    return offsetTop
  }

  public scrollToTheTableElement = () => {
    const scrollToElement: HTMLDivElement | null = window.document.querySelector('.inventory-list')
    console.log(scrollToElement instanceof HTMLDivElement)
    if (scrollToElement instanceof HTMLDivElement) {
      console.log(scrollToElement)
      const totalOffsetTop = this.getTotalOffsetTop(scrollToElement)
      window.scroll({
        // top: scrollToElement.offsetTop,
        top: totalOffsetTop,
        behavior: 'smooth',
      })
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState({
        searchText: value,
      })
      console.log(this.state.searchParams, 'searchparam')
      if (this.props.searchParamsData !== this.state.searchParams) {
        console.log('search param state change')
        this.setState({
          searchParams: this.props.searchParamsData,
        })
      }
      this.props.setSearchTextData(value)
      this.props.onSubmit(this.props.searchParamsData, value)
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public clearSearch = async () => {
    const {onClearSearch} = this.props
    this.setState({searchParams: {}})
    this.setState({searchText: ''})
    this.props.setSearchParamsData({})
    this.props.setSearchTextData('')
    this.state.searchParams.searchText = this.state.searchText
    await onClearSearch()
    this.setState({isSearched: false}) //set the variable true to log the search action called
    this.scrollToTheTableElement()
  }

  componentDidMount() {
    this.clearSearch()
  }

  public onChangeFilterButton = (searchItem: string, searchItemValue: string) => {
    if (searchItem === 'shape' && searchItemValue === 'all') {
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          [searchItem]: [searchItemValue],
        },
      })
    } else if (searchItem === 'size' && this.sizeSearchType === 'input') {
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          [searchItem]: [searchItemValue],
        },
      })
    } else {
      if (Object.keys(this.state.searchParams).length >= 1 && this.state.searchParams[searchItem]) {
        let filterIndex = Object.keys(this.state.searchParams[searchItem]).findIndex(
          (item) => this.state.searchParams[searchItem][item] === searchItemValue
        )
        if (filterIndex !== -1) {
          this.state.searchParams[searchItem].splice(filterIndex, 1) //remove the search element from particular search item
          if (this.state.searchParams[searchItem].length < 1) {
            delete this.state.searchParams[searchItem] //delete object if the filter last filter is remove from the search state
          }
          this.setState({
            searchParams: {...this.state.searchParams},
          })
        } else {
          this.setState({
            searchParams: {
              ...this.state.searchParams,
              [searchItem]: [...(this.state.searchParams[searchItem] || []), searchItemValue],
            },
          })
        }
      } else {
        this.setState({
          searchParams: {
            ...this.state.searchParams,
            [searchItem]: [...(this.state.searchParams[searchItem] || []), searchItemValue],
          },
        })
      }
    }
  }

  public toggleColorSelection(colorType: string) {
    if (colorType === 'white') {
      delete this.state.searchParams['fancy_color'] // remove object from search on toggle of color selection as at a time user can select only one color option
    } else {
      delete this.state.searchParams['white_color'] // remove object from search on toggle of color selection as at a time user can select only one color option
    }
    this.setState({
      colorType,
    })

    // search filter for fancy or white color
    this.setState({
      color_type: colorType,
    })

    this.setState({
      searchParams: {
        ...this.state.searchParams,
        color_type: colorType,
      },
    })
  }

  sizeSearchType: string = 'button'
  tempSearchValue: any = []
  public onChangeSizeInput(name: string, value: string) {
    if (this.state.searchParams['size'] && this.sizeSearchType === 'button') {
      delete this.state.searchParams['size']
      this.tempSearchValue = []
    }
    if (name === 'from') {
      this.tempSearchValue[0] = value
      // this.setState({sizeInputSearchVal: [value, this.state.sizeInputSearchVal[1] || '']})
    }
    if (name === 'to') {
      this.tempSearchValue[1] = value
      // this.setState({sizeInputSearchVal: [this.state.sizeInputSearchVal[0] || '', value]})
    }

    this.setState({sizeInputSearchVal: this.tempSearchValue})
    if (this.state.sizeInputSearchVal.length >= 2) {
      this.onChangeFilterButton('size', this.tempSearchValue.join('-'))
    }
    this.sizeSearchType = 'input'
  }

  public onChangeSizeButton(item: string, itemValue: string) {
    if (this.state.searchParams['size'] && this.sizeSearchType === 'input') {
      delete this.state.searchParams['size']
      this.tempSearchValue = []
    }
    this.onChangeFilterButton(item, itemValue)
    this.setState({sizeInputSearchVal: [], sizeSearchType: 'button'})
    this.sizeSearchType = 'button'
  }

  public render() {
    // const {data} = this.props
    const {searchParams, isSearched} = this.state

    return (
      <div className='mb-10'>
        <div className='card card-custom'>
          <Formik
            initialValues={{
              shape: '',
              size: '',
              color: '',
              clarity: '',
              finish: '',
              fluorescence_intensity: '',
              grading_report: '',
            }}
            validate={this.validateForm}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }: IRenderFormikForm) => {
              return (
                <>
                  <div
                    className='card-header cursor-pointer'
                    onClick={() => this.handleExpandClick()}
                  >
                    <h3 className='card-title table-card-title' style={{width: '25%'}}>
                      {'Diamond Search'}
                    </h3>
                    <div className='card-toolbar'>
                      {(searchParams && Object.keys(searchParams).length >= 1) || isSearched ? (
                        <div className='float-right mr-5'>
                          <InputButtonComponent onClick={() => this.clearSearch()}>
                            Clear Search
                          </InputButtonComponent>
                        </div>
                      ) : null}
                      <div className='float-right mr-5'>
                        <InputButtonComponent
                          onClick={handleSubmit}
                          disabled={
                            searchParams && Object.keys(searchParams).length >= 1 ? false : true
                          }
                        >
                          Search
                        </InputButtonComponent>
                      </div>
                      {/* <button
                        className='btn btn-icon btn-sm btn-hover-light-primary'
                        data-card-tool='toggle'
                        data-toggle='tooltip'
                        data-placement='top'
                        title='Toggle Card'
                        onClick={() => this.handleExpandClick()}
                      > */}
                      <i
                        className={`ki icon-nm ${this.state.expanded ? 'ki-minus' : 'ki-plus'}`}
                      ></i>
                      {/* </button> */}
                    </div>
                  </div>
                  <div className={`card-body ${this.state.expanded ? 'expand' : 'collapse'}`}>
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Shape'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[0] &&
                              config.inventorySearchParamsValue[0].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('shape', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['shape'] &&
                                        Object.keys(this.state.searchParams['shape']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['shape'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Size'}</label>
                          </div>
                          <div className='col-md-1'>
                            <input
                              name='from'
                              type='number'
                              min='0'
                              className={
                                touched && errors && errors['from']
                                  ? 'p-3 m-1 form-control form-control-solid border-danger'
                                  : 'p-3 m-1 form-control form-control-solid'
                              }
                              placeholder='From'
                              value={
                                this.state.sizeInputSearchVal &&
                                this.state.sizeInputSearchVal.length >= 1 &&
                                this.state.sizeInputSearchVal[0]
                                  ? this.state.sizeInputSearchVal[0]
                                  : ''
                              }
                              onChange={(event) =>
                                this.onChangeSizeInput('from', event.target.value)
                              }
                            />
                            {touched && errors && errors['from'] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <div className='fv-help-block'>{errors['from']}</div>
                              </div>
                            )}
                            <input
                              name='to'
                              type='number'
                              min='0'
                              value={
                                this.state.sizeInputSearchVal &&
                                this.state.sizeInputSearchVal.length >= 1 &&
                                this.state.sizeInputSearchVal[1]
                                  ? this.state.sizeInputSearchVal[1]
                                  : ''
                              }
                              className={
                                touched && errors && errors['to']
                                  ? 'p-3 m-1 form-control form-control-solid border-danger'
                                  : 'p-3 m-1 form-control form-control-solid'
                              }
                              placeholder='To'
                              onChange={(event) => this.onChangeSizeInput('to', event.target.value)}
                            />
                            {touched && errors && errors['to'] && (
                              <div className='fv-plugins-message-container text-danger'>
                                <div className='fv-help-block'>{errors['to']}</div>
                              </div>
                            )}
                          </div>
                          <div className='col-md-9'>
                            {config.inventorySearchParamsValue[1] &&
                              config.inventorySearchParamsValue[1].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeSizeButton('size', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['size'] &&
                                        Object.keys(this.state.searchParams['size']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['size'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <div className='row'>
                              <label className='font-weight-bold'>{'Color'}</label>
                            </div>
                            <div className='row'>
                              <div className='font-weight-bold col-lg-6'>
                                <a
                                  className={`${
                                    this.state.colorType === 'white' ? 'text-primary' : ''
                                  } `}
                                  onClick={() => this.toggleColorSelection('white')}
                                >
                                  {'White'}
                                </a>
                              </div>
                              <div className='font-weight-bold col-lg-6'>
                                <a
                                  className={`${
                                    this.state.colorType === 'fancy' ? 'text-primary' : ''
                                  } `}
                                  onClick={() => this.toggleColorSelection('fancy')}
                                >
                                  {'Fancy'}
                                </a>
                              </div>
                            </div>
                          </div>

                          {this.state.colorType === 'white' ? (
                            <div className='col-md-10'>
                              {config.inventorySearchParamsValue[2] &&
                                config.inventorySearchParamsValue[2].map(
                                  (
                                    searchItem: {value: string; label: string; icon: string},
                                    subIndex: number
                                  ) => {
                                    return (
                                      <button
                                        onClick={(e: any) =>
                                          this.onChangeFilterButton('white_color', searchItem.value)
                                        }
                                        className={`btn font-weight-bold p-3 m-1 ${
                                          Object.keys(this.state.searchParams).length >= 1 &&
                                          this.state.searchParams['white_color'] &&
                                          Object.keys(
                                            this.state.searchParams['white_color']
                                          ).findIndex(
                                            (subItem) =>
                                              this.state.searchParams['white_color'][subItem] ===
                                              searchItem.value
                                          ) !== -1
                                            ? 'btn-danger'
                                            : 'btn-secondary'
                                        }`}
                                      >
                                        {searchItem.label}
                                      </button>
                                    )
                                  }
                                )}
                            </div>
                          ) : (
                            <div className='col-md-10'>
                              {config.inventorySearchParamsValue[3] &&
                                config.inventorySearchParamsValue[3].map(
                                  (
                                    searchItem: {value: string; label: string; icon: string},
                                    subIndex: number
                                  ) => {
                                    return (
                                      <button
                                        onClick={(e: any) =>
                                          this.onChangeFilterButton('fancy_color', searchItem.value)
                                        }
                                        className={`btn font-weight-bold p-3 m-1 ${
                                          Object.keys(this.state.searchParams).length >= 1 &&
                                          this.state.searchParams['fancy_color'] &&
                                          Object.keys(
                                            this.state.searchParams['fancy_color']
                                          ).findIndex(
                                            (subItem) =>
                                              this.state.searchParams['fancy_color'][subItem] ===
                                              searchItem.value
                                          ) !== -1
                                            ? 'btn-danger'
                                            : 'btn-secondary'
                                        }`}
                                      >
                                        {searchItem.label}
                                      </button>
                                    )
                                  }
                                )}
                            </div>
                          )}
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Clarity'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[4] &&
                              config.inventorySearchParamsValue[4].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('clarity', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['clarity'] &&
                                        Object.keys(this.state.searchParams['clarity']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['clarity'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Lab'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[5] &&
                              config.inventorySearchParamsValue[5].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('lab', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['lab'] &&
                                        Object.keys(this.state.searchParams['lab']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['lab'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Cut'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[6] &&
                              config.inventorySearchParamsValue[6].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('cut', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['cut'] &&
                                        Object.keys(this.state.searchParams['cut']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['cut'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Polish'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[7] &&
                              config.inventorySearchParamsValue[7].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('polish', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['polish'] &&
                                        Object.keys(this.state.searchParams['polish']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['polish'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Symmetry'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[8] &&
                              config.inventorySearchParamsValue[8].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('symmetry', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['symmetry'] &&
                                        Object.keys(this.state.searchParams['symmetry']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['symmetry'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Fluorescence Intensity'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[9] &&
                              config.inventorySearchParamsValue[9].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton(
                                          'fluorescence_intensity',
                                          searchItem.value
                                        )
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['fluorescence_intensity'] &&
                                        Object.keys(
                                          this.state.searchParams['fluorescence_intensity']
                                        ).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['fluorescence_intensity'][
                                              subItem
                                            ] === searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Status'}</label>
                          </div>
                          <div className='col-md-10'>
                            {config.inventorySearchParamsValue[10] &&
                              config.inventorySearchParamsValue[10].map(
                                (
                                  searchItem: {value: string; label: string; icon: string},
                                  subIndex: number
                                ) => {
                                  return (
                                    <button
                                      onClick={(e: any) =>
                                        this.onChangeFilterButton('status', searchItem.value)
                                      }
                                      className={`btn font-weight-bold p-3 m-1 ${
                                        Object.keys(this.state.searchParams).length >= 1 &&
                                        this.state.searchParams['status'] &&
                                        Object.keys(this.state.searchParams['status']).findIndex(
                                          (subItem) =>
                                            this.state.searchParams['status'][subItem] ===
                                            searchItem.value
                                        ) !== -1
                                          ? 'btn-danger'
                                          : 'btn-secondary'
                                      }`}
                                    >
                                      {searchItem.label}
                                    </button>
                                  )
                                }
                              )}
                          </div>
                        </div>
                        <div className='row col-md-12 p-4'>
                          <div className='col-md-2'>
                            <label className='font-weight-bold'>{'Search Stock'}</label>
                          </div>
                          <div className='col-md-10'>
                            <h3 className='card-title table-card-title' style={{width: '25%'}}>
                              <InputSearchText
                                onChange={(value: string) => {
                                  this.onSearchChange(value)
                                }}
                                placeholder='Search Inventory'
                                input={{
                                  value: this.state.searchText,
                                }}
                              />
                            </h3>
                          </div>
                        </div>
                        {/* {config.inventorySearchParams.map(
                          (item: {value: string; label: string; index: number}, index: number) => {
                            return (
                              <div className='row col-md-12 p-4'>
                                <div className='col-md-2'>
                                  <label className='font-weight-bold'>{item.label}</label>
                                </div>
                                <div className='col-md-10'>
                                  {config.inventorySearchParamsValue[item.index] &&
                                    config.inventorySearchParamsValue[item.index].map(
                                      (
                                        searchItem: {value: string; label: string; icon: string},
                                        subIndex: number
                                      ) => {
                                        return (
                                          <button
                                            onClick={(e: any) =>
                                              this.onChangeFilterButton(
                                                item.value,
                                                searchItem.value
                                              )
                                            }
                                            className={`btn font-weight-bold p-3 m-1 ${
                                              Object.keys(this.state.searchParams).length >= 1 &&
                                              this.state.searchParams[item.value] &&
                                              Object.keys(
                                                this.state.searchParams[item.value]
                                              ).findIndex(
                                                (subItem) =>
                                                  this.state.searchParams[item.value][subItem] ===
                                                  searchItem.value
                                              ) !== -1
                                                ? 'btn-danger'
                                                : 'btn-secondary'
                                            }`}
                                          >
                                            {searchItem.label}
                                          </button>
                                        )
                                      }
                                    )}
                                </div>
                              </div>
                            )
                          }
                        )} */}
                      </div>

                      <div className='custom-card-footer'>
                        <div className='float-right'>
                          <InputButtonComponent
                            onClick={handleSubmit}
                            disabled={
                              searchParams && Object.keys(searchParams).length >= 1 ? false : true
                            }
                          >
                            Search
                          </InputButtonComponent>
                        </div>
                        {(searchParams && Object.keys(searchParams).length >= 1) || isSearched ? (
                          <div className='float-right mr-5'>
                            <InputButtonComponent onClick={() => this.clearSearch()}>
                              Clear Search
                            </InputButtonComponent>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  searchParamsData: state.inventory.searchParamsData,
  searchTextData: state.inventory.searchTextData,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSearchParamsData: (searchParams: any) => {
      dispatch(inventory.actions.setSearchParamsData(searchParams))
    },
    setSearchTextData: (searchTextData: any) => {
      dispatch(inventory.actions.setSearchTextData(searchTextData))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListSearchForm))
