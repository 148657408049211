import React, {useState, useEffect} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'

const ListVendorData = ({...props}) => {
  const {vendorData, handleCancel, setSelectedVendorData, selectedVendorData} = props
  const [vendorPagination, setVendorPagination] = useState({
    pageSize: 10,
    current: 1,
  })

  const [counter, setCounter] = useState(0)
  const [tempCounter, setTempCounter] = useState(0)

  const handleVendorTableChange = (paginationConfig: any) => {
    // Implement table change logic here if needed
    setVendorPagination({
      ...vendorPagination,
      ...paginationConfig,
    })
  }

  const handlePaginationChange = (page: any) => {
    if (page - 1) {
      const newCounter = (page - 1) * vendorPagination.pageSize
      setCounter(newCounter)
      setTempCounter(newCounter)
      return
    }

    setCounter(0)
    setTempCounter(0)
  }

  const columns = [
    // {
    //   title: 'Sr. No.',
    //   key: 'serial_number',
    //   fixed: window.innerWidth < 768 ? false : true,
    //   width: 80,
    //   render: (text: any, item: any, index: number) => {
    //     return (
    //       <div>
    //         <span>{++index + this.tempCounter}</span>
    //       </div>
    //     )
    //   },
    // },
    {
      title: 'Full Name',
      key: 'Full Name',
      width: 100,

      // fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item.first_name && item.last_name
                ? `${item.first_name} ${item.last_name}`
                : item.first_name
                ? item.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    // {
    //   title: 'id',
    //   key: 'id',
    //   // fixed: window.innerWidth < 768 ? false : true,
    //   render: (text: any, item: any, index: number) => {
    //     return <div>{item.id ? item.id : '-'}</div>
    //   },
    // },
    {
      title: 'Email',
      key: 'email',
      width: 1,
      render: (text: any, item: any, index: number) => {
        return <div>{item.email ? item.email : '-'}</div>
      },
    },
    {
      title: 'Phone Number',
      key: 'phone',
      width: 10,
      render: (text: any, item: any, index: number) => {
        return <div>{item.phone ? item.phone : '-'}</div>
      },
    },
  ]
  const [selectedRowsData, setSelectedRowsData] = useState([])
  const [dataTableSelectedRowKeys, setDataTableSelectedRowKeys] = useState<any>([])

 

  const rowSelection = {
    onChange: (selectedRowKeys: Array<any>, selectedRows: any) => {
      setDataTableSelectedRowKeys([selectedRowKeys[selectedRowKeys.length - 1]])
      // setSelectedRowsData(selectedRows)
      if(selectedRowKeys.length){
        handleCancel()
      }
      setSelectedVendorData([selectedRows[selectedRowKeys.length - 1]])
    },
    selectedRowKeys: selectedVendorData.length ? dataTableSelectedRowKeys : [],
  }

  return (
    <ListTable
      rowKey={(record: any) => record.id}
      onChange={handleVendorTableChange}
      column={columns}
      onPaginationChange={handlePaginationChange}
      paginationConfig={{
        total: vendorData?.length ?? 0,
        showSizeChanger: true,
        pageSize: vendorPagination.pageSize,
        current: vendorPagination.current,
      }}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
        // selectedRowKeys: dataTableSelectedRowKeys ? dataTableSelectedRowKeys : [],
      }}
      isLoading={false}
      dataSource={vendorData}
      counter={counter}
      tempCounter={tempCounter}
      isScroll={true}
      scrollWidth={0}
    />
  )
}

export default ListVendorData
