/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {importInventoryData, getInventoryData} from '../redux/InventoryCRUD'
import * as inventory from '../redux/InventoryRedux'
import {IImportInventoryFormValues, ImportInventoryForm} from './ImportInventoryForm'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {VendorImportInventoryForm} from './VendorImportInventoryForm'
import {Toaster} from '../../../modules/comman/components/ToasterNoti'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [isLoading, setIsLoading] = useState(false)

  const callInventoryData = async () => {
    try {
      let inventoryData = await getInventoryData(0, 10, false)
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setInventoryData(inventoryData.data))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.count))
        history.push('/inventory/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IImportInventoryFormValues) => {
    try {
      setIsLoading(true)
      const resMsg: any = await importInventoryData(values)
      if (resMsg.status === 200) {
        await callInventoryData()
        Toaster({
          type: 'success',
          title: 'Inventory',
          description: 'Inventory data added successFully',
        })
      } else {
        Toaster({
          type: 'error',
          title: 'Inventory',
          description: resMsg?.message ?? 'Something Went Wrong',
        })
      }
      setIsLoading(false)
    } catch (e) {
      console.log('!!!!Error', e)
      setIsLoading(false)

    }
  }

  return (
    <div>
      {isAuthorized.role == 'super_admin' || isAuthorized.role == 'super_admin' ? (
        <ImportInventoryForm onSubmit={(values: IImportInventoryFormValues) => addSubmit(values)} isImportLoading={isLoading} setImportLoading={setIsLoading}/>
      ) : isAuthorized.role == 'vendor' ? (
        <VendorImportInventoryForm
          onSubmit={(values: IImportInventoryFormValues) => addSubmit(values)} isImportLoading={isLoading}
        />
      ) : null}
    </div>
  )
}

const ImportWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'import',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'import',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ImportWrapper}
