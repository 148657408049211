/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAllKpisData} from '../redux/DashboardCRUD'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as dashboard from '../redux/DashboardRedux'
import {KpiCardComponent} from '../../../modules/comman/components/KpiCardComponent'
import {RootState} from '../../../../setup'
import {Card} from 'antd'
import StatisticCard from './StatasticsCard'
import {useHistory} from 'react-router'

const DashboardPage: FC = () => {
  const dispatch = useDispatch()
  const dashboardDataStore = useSelector((state: any) => state.dashboard.dashboardData)
  const dashboardLoadingStore = useSelector((state: any) => state.dashboard.dashboardLoading)
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const history = useHistory()

  const navigateToPath = (path: any) => {
    if (path) {
      history.push(path)
    }
  }
  const {role, permission} = user
  const callInitDashboardData = async () => {
    try {
      let dashboardData = await getAllKpisData()
      if (dashboardData.data) {
        await dispatch(dashboard.actions.setDashboardLoading(true))
        // once dashboard data set then loading will automatically off using saga middleware
        await dispatch(dashboard.actions.setDashboardData(dashboardData.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitDashboardData()
  }, [])

  const buyRequestData = dashboardDataStore ? dashboardDataStore.buy_request : undefined
  const orderData = dashboardDataStore ? dashboardDataStore.order : undefined
  const vendorOrderData = dashboardDataStore ? dashboardDataStore.vendor_order : undefined
  const returnOrderData = dashboardDataStore
    ? dashboardDataStore.return_order_status_count
    : undefined
  const stockOfferData = dashboardDataStore
    ? dashboardDataStore.stock_offer_status_count
    : undefined

  let pendingBuyRequestCount,
    acceptedBuyRequestCount,
    updatedBuyRequestCount,
    pendingOrderCount,
    deliveredOrderCount,
    pendingVendorOrderCount,
    shippedVendorOrderCount,
    paidVendorOrderCount,
    pendingReturnOrderCount,
    clientShippedReturnOrderCount,
    vendorShippedReturnOrderCount,
    recievedReturnOrderCount,
    pendingStockOfferCount,
    acceptedStockOfferCount,
    rejectedStockOfferCount,
    revisedStockOfferCount = 0

  if (buyRequestData) {
    pendingBuyRequestCount =
      buyRequestData.find((item: any) => item.status === 'PENDING')?.count || 0
    acceptedBuyRequestCount =
      buyRequestData.find((item: any) => item.status === 'ACCEPTED')?.count || 0
    updatedBuyRequestCount =
      buyRequestData.find((item: any) => item.status === 'UPDATED')?.count || 0
  }

  if (orderData) {
    pendingOrderCount = orderData.find((item: any) => item.order_status === 'PENDING')?.count || 0
    deliveredOrderCount =
      orderData.find((item: any) => item.order_status === 'DELIVERED')?.count || 0
  }

  if (vendorOrderData) {
    pendingVendorOrderCount =
      vendorOrderData.find((item: any) => item.status === 'PENDING')?.count || 0
    shippedVendorOrderCount =
      vendorOrderData.find((item: any) => item.status === 'SHIPPED')?.count || 0
    paidVendorOrderCount = vendorOrderData.find((item: any) => item.status === 'PAID')?.count || 0
  }

  if (returnOrderData) {
    pendingReturnOrderCount =
      returnOrderData.find((item: any) => item.status === 'PENDING')?.count || 0
    clientShippedReturnOrderCount =
      returnOrderData.find((item: any) => item.status === 'CLIENT-SHIPPED')?.count || 0
    vendorShippedReturnOrderCount =
      returnOrderData.find((item: any) => item.status === 'VENDOR-SHIPPED')?.count || 0
    recievedReturnOrderCount =
      returnOrderData.find((item: any) => item.status === 'RECEIVED')?.count || 0
  }

  if (stockOfferData) {
    pendingStockOfferCount =
      stockOfferData.find((item: any) => item.status === 'PENDING')?.count || 0
    acceptedStockOfferCount =
      stockOfferData.find((item: any) => item.status === 'ACCEPTED')?.count || 0
    rejectedStockOfferCount =
      stockOfferData.find((item: any) => item.status === 'REJECTED')?.count || 0
    revisedStockOfferCount =
      stockOfferData.find((item: any) => item.status === 'REVISED')?.count || 0
  }

  return (
    <div>
      {dashboardLoadingStore ? (
        <div></div>
      ) : (
        <>
          {role == 'super_admin' && (
            <>
              <div className='row g-5 g-xl-8'>
                <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Admin Stocks'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.admin_stock_count
                        ? dashboardDataStore.admin_stock_count
                        : 0
                    )
                  }
                  path='/inventory/list'
                  background='white'
                  subValue={dashboardDataStore?.stock_status_count}
                />
                <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Vendor Stocks'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.vendors_stock_count
                        ? dashboardDataStore.vendors_stock_count
                        : 0
                    )
                  }
                  path='/inventory/list'
                  background='white'
                  subValue={dashboardDataStore?.vendors_stock_status_count}
                />
                <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Sub Admins'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.subAdmin_count ? dashboardDataStore.subAdmin_count : 0
                    )
                  }
                  path='/subadmin/list'
                  background='white'
                />
                <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Users'
                  value={
                    dashboardDataStore &&
                    String(dashboardDataStore.users_count ? dashboardDataStore.users_count : 0)
                  }
                  path='/users/list'
                  background='white'
                />
                <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Vendors'
                  value={
                    dashboardDataStore &&
                    String(dashboardDataStore.vendors_count ? dashboardDataStore.vendors_count : 0)
                  }
                  path='/vendors/list'
                  background='white'
                />
                {/* <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Buy Request'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.buy_request_count
                        ? dashboardDataStore.buy_request_count
                        : 0
                    )
                  }
                  path='/buy-request/list'
                  background='white'
                /> */}
                {/* <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Orders'
                  value={
                    dashboardDataStore &&
                    String(dashboardDataStore.order_count ? dashboardDataStore.order_count : 0)
                  }
                  path='/order/list'
                  background='white'
                /> */}
                {/* <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Vendor Orders'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.vendor_orders_count
                        ? dashboardDataStore.vendor_orders_count
                        : 0
                    )
                  }
                  path='/vendor-order/list'
                  background='white'
                /> */}
              </div>
              <div className='row g-5 g-xl-8'>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='BUY REQUEST'
                    bordered={false}
                    style={{width: '100%'}}
                    onClick={() => {
                      navigateToPath('/buy-request/list')
                    }}
                  >
                    <StatisticCard
                      title='Buy Request'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.buy_request_count
                            ? dashboardDataStore.buy_request_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='Pending'
                      value={pendingBuyRequestCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='Accepted'
                      value={acceptedBuyRequestCount}
                      titleColor='text-success'
                    />
                    <StatisticCard
                      title='Updated'
                      value={updatedBuyRequestCount}
                      titleColor='text-danger'
                    />
                  </Card>
                </div>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='ORDER'
                    bordered={false}
                    style={{width: '100%', height: '220px'}}
                    onClick={() => {
                      navigateToPath('/order/list')
                    }}
                  >
                    <StatisticCard
                      title='ORDER'
                      value={dashboardDataStore?.order_count ? dashboardDataStore.order_count : 0}
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='Pending'
                      value={pendingOrderCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='Accepted'
                      value={deliveredOrderCount}
                      titleColor='text-success'
                    />
                  </Card>
                </div>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='VENDOR ORDER'
                    bordered={false}
                    style={{width: '100%', height: '220px'}}
                    onClick={() => {
                      navigateToPath('/vendor-order/list')
                    }}
                  >
                    <StatisticCard
                      title='VENDOR ORDER'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.vendor_orders_count
                            ? dashboardDataStore.vendor_orders_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='Pending'
                      value={pendingVendorOrderCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='Shipped'
                      value={shippedVendorOrderCount}
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='Paid'
                      value={paidVendorOrderCount}
                      titleColor='text-success'
                    />
                  </Card>
                </div>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='RETURN ORDER'
                    bordered={false}
                    style={{width: '100%', height: '220px'}}
                    onClick={() => {
                      navigateToPath('/returnorder/list')
                    }}
                  >
                    <StatisticCard
                      title='RETURN ORDER'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.return_orders_count
                            ? dashboardDataStore.return_orders_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='PENDING'
                      value={pendingReturnOrderCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='CLIENT-SHIPPED'
                      value={clientShippedReturnOrderCount}
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='VENDOR-SHIPPED'
                      value={vendorShippedReturnOrderCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='RECEIVED'
                      value={recievedReturnOrderCount}
                      titleColor='text-success'
                    />
                  </Card>
                </div>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='STOCK OFFER'
                    bordered={false}
                    style={{width: '100%', height: '220px'}}
                    onClick={() => {
                      navigateToPath('/clientoffer/list')
                    }}
                  >
                    <StatisticCard
                      title='STOCK OFFER'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.stock_offers_count
                            ? dashboardDataStore.stock_offers_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='PENDING'
                      value={pendingStockOfferCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='ACCEPTED'
                      value={acceptedStockOfferCount}
                      titleColor='text-success'
                    />
                    <StatisticCard
                      title='REJECTED'
                      value={rejectedStockOfferCount}
                      titleColor='text-danger'
                    />
                    <StatisticCard
                      title='REVISED'
                      value={revisedStockOfferCount}
                      titleColor='text-warning'
                    />
                  </Card>
                </div>
              </div>
            </>
          )}
          {role == 'sub_admin' && (
            <>
              <div className='row g-5 g-xl-8'>
                <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Admin Stocks'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.admin_stock_count
                        ? dashboardDataStore.admin_stock_count
                        : 0
                    )
                  }
                  path='/inventory/list'
                  background='white'
                />
                {permission?.list_inventory && (
                  <KpiCardComponent
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Vendor Stocks'
                    value={
                      dashboardDataStore &&
                      String(
                        dashboardDataStore.vendors_stock_count
                          ? dashboardDataStore.vendors_stock_count
                          : 0
                      )
                    }
                    path='/inventory/list'
                    background='white'
                  />
                )}
                {permission?.user && (
                  <KpiCardComponent
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Users'
                    value={
                      dashboardDataStore &&
                      String(dashboardDataStore.users_count ? dashboardDataStore.users_count : 0)
                    }
                    path='/users/list'
                    background='white'
                  />
                )}
                {permission?.vendors && (
                  <KpiCardComponent
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Vendors'
                    value={
                      dashboardDataStore &&
                      String(
                        dashboardDataStore.vendors_count ? dashboardDataStore.vendors_count : 0
                      )
                    }
                    path='/vendors/list'
                    background='white'
                  />
                )}
                {permission?.buyrequest && (
                  <KpiCardComponent
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Buy Request'
                    value={
                      dashboardDataStore &&
                      String(
                        dashboardDataStore.buy_request_count
                          ? dashboardDataStore.buy_request_count
                          : 0
                      )
                    }
                    path='/buy-request/list'
                    background='white'
                  />
                )}
                {permission?.order && (
                  <KpiCardComponent
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Orders'
                    value={
                      dashboardDataStore &&
                      String(dashboardDataStore.order_count ? dashboardDataStore.order_count : 0)
                    }
                    path='/order/list'
                    background='white'
                  />
                )}
                {permission?.vendororder && (
                  <KpiCardComponent
                    icon='/media/icons/duotune/art/art002.svg'
                    title='Vendor Orders'
                    value={
                      dashboardDataStore &&
                      String(
                        dashboardDataStore.vendor_orders_count
                          ? dashboardDataStore.vendor_orders_count
                          : 0
                      )
                    }
                    path='/vendor-order/list'
                    background='white'
                  />
                )}
              </div>
              <div className='row g-5 g-xl-8'>
                {permission?.buyrequest && (
                  <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                    <Card
                      title='BUY REQUEST'
                      bordered={false}
                      style={{width: '100%'}}
                      onClick={() => {
                        navigateToPath('/buy-request/list')
                      }}
                    >
                      <StatisticCard
                        title='Buy Request'
                        value={
                          dashboardDataStore &&
                          String(
                            dashboardDataStore.buy_request_count
                              ? dashboardDataStore.buy_request_count
                              : 0
                          )
                        }
                        titleColor='text-primary'
                      />
                      <StatisticCard
                        title='Pending'
                        value={pendingBuyRequestCount}
                        titleColor='text-warning'
                      />
                      <StatisticCard
                        title='Accepted'
                        value={acceptedBuyRequestCount}
                        titleColor='text-success'
                      />
                      <StatisticCard
                        title='Updated'
                        value={updatedBuyRequestCount}
                        titleColor='text-danger'
                      />
                    </Card>
                  </div>
                )}
                {permission?.order && (
                  <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                    <Card
                      title='ORDER'
                      bordered={false}
                      style={{width: '100%', height: '220px'}}
                      onClick={() => {
                        navigateToPath('/order/list')
                      }}
                    >
                      <StatisticCard
                        title='ORDER'
                        value={dashboardDataStore.order_count ? dashboardDataStore.order_count : 0}
                        titleColor='text-primary'
                      />
                      <StatisticCard
                        title='Pending'
                        value={pendingOrderCount}
                        titleColor='text-warning'
                      />
                      <StatisticCard
                        title='Accepted'
                        value={deliveredOrderCount}
                        titleColor='text-success'
                      />
                    </Card>
                  </div>
                )}
                {permission?.vendororder && (
                  <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                    <Card
                      title='VENDOR ORDER'
                      bordered={false}
                      style={{width: '100%', height: '220px'}}
                      onClick={() => {
                        navigateToPath('/vendor-order/list')
                      }}
                    >
                      <StatisticCard
                        title='VENDOR ORDER'
                        value={
                          dashboardDataStore &&
                          String(
                            dashboardDataStore.vendor_orders_count
                              ? dashboardDataStore.vendor_orders_count
                              : 0
                          )
                        }
                        titleColor='text-primary'
                      />
                      <StatisticCard
                        title='Pending'
                        value={pendingVendorOrderCount}
                        titleColor='text-warning'
                      />
                      <StatisticCard
                        title='Shipped'
                        value={shippedVendorOrderCount}
                        titleColor='text-primary'
                      />
                      <StatisticCard
                        title='Paid'
                        value={paidVendorOrderCount}
                        titleColor='text-success'
                      />
                    </Card>
                  </div>
                )}
                {permission?.return_order && (
                  <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                    <Card
                      title='RETURN ORDER'
                      bordered={false}
                      style={{width: '100%', height: '220px'}}
                      onClick={() => {
                        navigateToPath('/returnorder/list')
                      }}
                    >
                      <StatisticCard
                        title='RETURN ORDER'
                        value={
                          dashboardDataStore &&
                          String(
                            dashboardDataStore.return_orders_count
                              ? dashboardDataStore.return_orders_count
                              : 0
                          )
                        }
                        titleColor='text-primary'
                      />
                      <StatisticCard
                        title='PENDING'
                        value={pendingReturnOrderCount}
                        titleColor='text-warning'
                      />
                      <StatisticCard
                        title='CLIENT-SHIPPED'
                        value={clientShippedReturnOrderCount}
                        titleColor='text-primary'
                      />
                      <StatisticCard
                        title='VENDOR-SHIPPED'
                        value={vendorShippedReturnOrderCount}
                        titleColor='text-warning'
                      />
                      <StatisticCard
                        title='RECEIVED'
                        value={recievedReturnOrderCount}
                        titleColor='text-success'
                      />
                    </Card>
                  </div>
                )}
                {permission.stock_offer && (
                  <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                    <Card
                      title='STOCK OFFER'
                      bordered={false}
                      style={{width: '100%', height: '220px'}}
                      onClick={() => {
                        navigateToPath('/clientoffer/list')
                      }}
                    >
                      <StatisticCard
                        title='STOCK OFFER'
                        value={
                          dashboardDataStore &&
                          String(
                            dashboardDataStore.stock_offers_count
                              ? dashboardDataStore.stock_offers_count
                              : 0
                          )
                        }
                        titleColor='text-primary'
                      />
                      <StatisticCard
                        title='PENDING'
                        value={pendingStockOfferCount}
                        titleColor='text-warning'
                      />
                      <StatisticCard
                        title='ACCEPTED'
                        value={acceptedStockOfferCount}
                        titleColor='text-success'
                      />
                      <StatisticCard
                        title='REJECTED'
                        value={rejectedStockOfferCount}
                        titleColor='text-danger'
                      />
                      <StatisticCard
                        title='REVISED'
                        value={revisedStockOfferCount}
                        titleColor='text-warning'
                      />
                    </Card>
                  </div>
                )}
              </div>
            </>
          )}
          {role == 'vendor' && (
            <>
              <div className='row g-5 g-xl-8'>
                <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Vendor Stocks'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.vendors_stock_count
                        ? dashboardDataStore.vendors_stock_count
                        : 0
                    )
                  }
                  path='/inventory/list'
                  background='white'
                />
                {/* <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Stock Request'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.buy_request_count
                        ? dashboardDataStore.buy_request_count
                        : 0
                    )
                  }
                  path='/vendor-stock/list'
                  background='white'
                /> */}
                {/* <KpiCardComponent
                  icon='/media/icons/duotune/art/art002.svg'
                  title='Vendor Orders'
                  value={
                    dashboardDataStore &&
                    String(
                      dashboardDataStore.vendor_orders_count
                        ? dashboardDataStore.vendor_orders_count
                        : 0
                    )
                  }
                  path='/vendor-order/list'
                  background='white'
                /> */}
              </div>
              <div className='row g-5 g-xl-8'>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='STOCK REQUEST'
                    bordered={false}
                    style={{width: '100%'}}
                    onClick={() => {
                      navigateToPath('/vendor-stock/list')
                    }}
                  >
                    <StatisticCard
                      title='Stock Request'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.buy_request_count
                            ? dashboardDataStore.buy_request_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='Pending'
                      value={pendingBuyRequestCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='Accepted'
                      value={acceptedBuyRequestCount}
                      titleColor='text-success'
                    />
                    <StatisticCard
                      title='Updated'
                      value={updatedBuyRequestCount}
                      titleColor='text-danger'
                    />
                  </Card>
                </div>
                <div
                  className='col-lg-3 col-md-4 col-12'
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    navigateToPath('/vendor-order/list')
                  }}
                >
                  <Card
                    title='VENDOR ORDER'
                    bordered={false}
                    style={{width: '100%', height: '220px'}}
                  >
                    <StatisticCard
                      title='VENDOR ORDER'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.vendor_orders_count
                            ? dashboardDataStore.vendor_orders_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='Pending'
                      value={pendingVendorOrderCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='Shipped'
                      value={shippedVendorOrderCount}
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='Paid'
                      value={paidVendorOrderCount}
                      titleColor='text-success'
                    />
                  </Card>
                </div>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='RETURN ORDER'
                    bordered={false}
                    style={{width: '100%', height: '220px'}}
                    onClick={() => {
                      navigateToPath('/returnorder/list')
                    }}
                  >
                    <StatisticCard
                      title='RETURN ORDER'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.return_orders_count
                            ? dashboardDataStore.return_orders_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='PENDING'
                      value={pendingReturnOrderCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='CLIENT-SHIPPED'
                      value={clientShippedReturnOrderCount}
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='VENDOR-SHIPPED'
                      value={vendorShippedReturnOrderCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='RECEIVED'
                      value={recievedReturnOrderCount}
                      titleColor='text-success'
                    />
                  </Card>
                </div>
                <div className='col-lg-3 col-md-4 col-12' style={{cursor: 'pointer'}}>
                  <Card
                    title='STOCK OFFER'
                    bordered={false}
                    style={{width: '100%', height: '220px'}}
                    onClick={() => {
                      navigateToPath('/clientoffer/list')
                    }}
                  >
                    <StatisticCard
                      title='STOCK OFFER'
                      value={
                        dashboardDataStore &&
                        String(
                          dashboardDataStore.stock_offers_count
                            ? dashboardDataStore.stock_offers_count
                            : 0
                        )
                      }
                      titleColor='text-primary'
                    />
                    <StatisticCard
                      title='PENDING'
                      value={pendingStockOfferCount}
                      titleColor='text-warning'
                    />
                    <StatisticCard
                      title='ACCEPTED'
                      value={acceptedStockOfferCount}
                      titleColor='text-success'
                    />
                    <StatisticCard
                      title='REJECTED'
                      value={rejectedStockOfferCount}
                      titleColor='text-danger'
                    />
                    <StatisticCard
                      title='REVISED'
                      value={revisedStockOfferCount}
                      titleColor='text-warning'
                    />
                  </Card>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'dashboard',
            path: '/dashboard',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.DASHBOARD'})}
      </PageTitle>
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
