import React, {useState, useRef, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Tooltip} from 'antd'
import {Button} from 'antd'
import {
  UploadOutlined,
  AudioOutlined,
  SendOutlined,
  PauseCircleOutlined,
  DeleteOutlined,
  CloseOutlined,
  DownOutlined,
} from '@ant-design/icons'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {Spin} from 'antd'
import {Tag} from 'antd'
import {Badge} from 'antd'
import {getChatUserListData, uploadChatAudio, getVariantPriceApi} from '../redux/ChatsCRUD'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import UploadMediaModal from './UploadMediaModal'
// import {config} from '../../../comman/constants'
import PullToRefresh from 'react-simple-pull-to-refresh'
import * as chatsredux from '../redux/ChatsRedux'
import {UpdateVariantPriceForm} from './UpdateVariantPriceForm'

export default function ChatMessages({socket}: any) {
  const dispatch = useDispatch()
  const [activeChat, setActiveChat]: any = useState(null) // when a particular chat room data is selected this state is updated
  const [roomId, setRoomId]: any = useState(null) // active room id state
  const [variantPrice, setVariantPrice]: any = useState(0) // active room id state

  // Audio recording chat state
  const [recording, setRecording]: any = useState([])
  const [isRecording, setIsRecording] = useState(false)
  const audioChunk: any = useRef([])
  const mediaRecorderRef: any = useRef(null)
  const streamRef: any = useRef(null)
  // all messages of room
  const [messages, setMessages]: any = useState([])
  const [totalMessagesLoaded, setTotalMessagesLoaded] = useState(20)
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false)
  const [allMessagesLoaded, setAllMessagesLoaded] = useState(false)
  const [variantModel, setVariantModel] = useState(false)
  // input text message state
  const [message, setMessage]: any = useState('')
  // chat container to load messages on scroll
  const chatContainerRef = useRef<HTMLDivElement>(null)
  const authToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const adminData: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  // users list redux state
  const chats = useSelector<RootState>(({chats}) => chats.chatsData, shallowEqual)

  // open a particular image enlarge using modal
  const [modalOpen, setModalOpen] = useState(false)
  const [modalImage, setModalImage]: any = useState(null)

  // upload Modal
  const [uploadModal, setUploadModal]: any = useState(null)

  // scroll chat to bottom directly button show or hide state
  const [showScrollDownButton, setShowScrollDownButton] = useState(false)

  const [refreshing, setRefreshing] = useState(false)

  const showPreviewModal = async () => {
    setVariantModel(true)
  }

  const closeEditModal = async () => {
    setVariantModel(false)
   
    setRoomId(null)
    handleChatClick(activeChat)
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const variantPriceData: any = await getVariantPriceApi(roomId)
        console.log({variantPriceData: variantPriceData?.inquiry?.variant_price})
        setVariantPrice(variantPriceData?.inquiry?.variant_price || 0)
      } catch (error) {
        console.error(error)
      }
    }

    if (activeChat && roomId) {
      fetchData()
    }
  }, [activeChat, roomId, variantModel])
  // use effect to listen the current sent messages and get message
  useEffect(() => {
    const handleGetMessage = (data: any) => {
      console.log(data, 'getMessagedata')
      if (data && adminData && adminData.id && adminData.id == data.requested_by_id) {
        if (data.messages && Array.isArray(data.messages) && data.messages.length) {
          data.messages.reverse()
          const newMessages = data.messages.map((item: any) => {
            console.log({newMessages: item})

            if (item.message) {
              return {
                _id: item._id,
                message:
                  item.type === 'checkout'
                    ? `Updated variant price : $${JSON.parse(item.message)?.variant_price}`
                    : item.message,
                from_admin: item.from_admin,
                createdAt: item.createdAt,
                type: item?.type,
              }
            } else {
              return {
                _id: item._id,
                media: item.media,
                from_admin: item.from_admin,
                createdAt: item.createdAt,
                type: item?.type,
              }
            }
          })
          setMessages((prevMessages: any) => {
            // Ensure prevMessages is initialized and not undefined
            const filteredMessages = newMessages.filter(
              (newMsg: any) =>
                prevMessages &&
                !prevMessages.some(
                  (prevMsg: any) => prevMsg._id === newMsg._id // Comparing messages based on _id
                )
            )
            console.log(filteredMessages, prevMessages, 'check messages')
            return [...filteredMessages, ...(prevMessages || [])] // Use empty array as fallback for prevMessages
          })
        } else {
          setAllMessagesLoaded(true)
        }
      }
      setLoadingMoreMessages(false)
    }

    const handleGetCurrentMessage = (data: any) => {
      console.log(data.messages.message, 'getCurrentMessagedata')
      const currentMessageObject = {
        message: data.messages.message,
        from_admin: data.messages.from_admin,
        createdAt: data.messages.createdAt,
        _id: data.messages._id,
        media: data.messages.media,
      }

      setMessages((prevMessages: any) => [...prevMessages, currentMessageObject])
    }

    socket.on('getMessage', handleGetMessage)
    socket.on('getCurrentMessage', handleGetCurrentMessage)

    return () => {
      socket.off('getMessage', handleGetMessage)
      socket.off('getCurrentMessage', handleGetCurrentMessage)
      socket.emit('leaveRoom', {room_id: roomId})
    }
  }, [socket])

  // Scroll to the bottom only when a new message is added
  useEffect(() => {
    if (chatContainerRef.current && !loadingMoreMessages) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
      console.log('scrolled to bottom due to new message')
    }
  }, [messages])

  // Keep track of scroll position and maintain it when loading older messages
  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        // Check if the user has scrolled to the top
        if (chatContainerRef.current) {
          const {scrollTop, scrollHeight, clientHeight} = chatContainerRef.current
          if (scrollTop + clientHeight < scrollHeight - 50) {
            setShowScrollDownButton(true)
          } else {
            setShowScrollDownButton(false)
          }
        }

        if (
          chatContainerRef.current.scrollTop === 0 &&
          !loadingMoreMessages &&
          !allMessagesLoaded
        ) {
          // Load more messages
          setLoadingMoreMessages(true)
          const previousScrollHeight = chatContainerRef.current.scrollHeight
          socket.emit('receiveMessage', {
            room_id: roomId,
            skip: messages.length,
            limit: 20,
            token: authToken,
          })
          setTotalMessagesLoaded((prevCount) => prevCount + 20)

          // Restore the previous scroll position
          setTimeout(() => {
            if (chatContainerRef.current) {
              chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight - previousScrollHeight
            }
          }, 100)
        }
      }
    }

    if (chatContainerRef.current) {
      chatContainerRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (chatContainerRef.current) {
        chatContainerRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [socket, roomId, messages, loadingMoreMessages])

  // when a user chat room is selected or switched we emit join room and emit recieve Message socket to get first 20 messages
  const handleChatClick = (chat: any) => {
    if (roomId) {
      closeActiveChat()
    }
    setTotalMessagesLoaded(20)
    setAllMessagesLoaded(false)
    if (chat && chat.data && chat.data.room_id) {
      setActiveChat(chat)
      setRoomId(chat.data.room_id)
      // Clean up the existing joinedRoom listener to avoid duplicates
      socket.off('joinedRoom')

      // Join the new room
      socket.emit('joinRoom', {room_id: chat.data.room_id, token: authToken})
      socket.emit('inquiry', {room_id: chat.data.room_id, token: authToken})
      // Listen for the joinedRoom event
      socket.on('joinedRoom', (data: any) => {
        console.log('Room Joined room id: ', data)
      })

      // Emit receiveMessage to get the initial messages
      socket.emit('receiveMessage', {
        room_id: chat.data.room_id,
        skip: 0,
        limit: 20,
        token: authToken,
      })
    }
  }

  // when the user switches chat we need to clear room id messages and emit leave room socket
  const closeActiveChat = () => {
    socket.emit('leaveRoom', {room_id: roomId})
    setActiveChat(null)
    setRoomId(null)
    setMessages([])
    setAllMessagesLoaded(false)
  }

  // audio pause play button click
  const handleAudioClick = () => {
    if (isRecording) {
      stopRec()
    } else {
      startRec()
    }
    setIsRecording(!isRecording)
  }

  // start recording audio
  const startRec = async () => {
    audioChunk.current = []
    const stream = await navigator.mediaDevices.getUserMedia({audio: true})
    streamRef.current = stream // Store the stream
    const mediaRecorder = new MediaRecorder(stream)
    mediaRecorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        audioChunk.current.push(e.data)
      }
    }
    mediaRecorder.onstop = () => {
      const audioBlob = new Blob(audioChunk.current, {type: 'audio/wav'})
      const audioUrl = URL.createObjectURL(audioBlob)
      let audioData = []
      audioData.push(audioUrl)
      setRecording(audioData)
    }
    mediaRecorder.start()
    mediaRecorderRef.current = mediaRecorder
  }

  // stop recording audio
  const stopRec = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop()
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track: any) => track.stop()) // Stop all tracks in the stream
      streamRef.current = null // Clear the reference to the stream
    }
  }

  // delete the audio recording
  const deleteRecording = () => {
    setRecording([])
  }

  // send audio recording to server
  const sendRecordingToServer = async () => {
    try {
      if (recording.length > 0) {
        const audioBlob = await fetch(recording[0]).then((res) => res.blob())
        let formData = new FormData()
        formData.append('file', audioBlob, 'audio.wav') // Ensure 'file' key is used

        // Example using uploadChatAudio function
        let audioUploadResponse = await uploadChatAudio(formData)
        console.log(audioUploadResponse)
        if (audioUploadResponse && audioUploadResponse.status == 200 && audioUploadResponse.file) {
          let fileName = audioUploadResponse.file
          socket.emit('sendMessage', {
            message: '',
            room_id: roomId,
            from_admin: true,
            media: [
              {
                type: 'audio',
                file_url: fileName ? fileName : '',
              },
            ],
            type: 'text',
            token: authToken,
          })
        }
      }
    } catch (e) {
      console.log(e)
    }
    setIsRecording(false)
    setRecording([])
  }

  // send Media to server
  const handleMediaUploadFiles = async (uploadMedia: any) => {
    try {
      console.log(uploadMedia, 'data outside condition')
      if (uploadMedia && Array.isArray(uploadMedia) && uploadMedia.length) {
        console.log(uploadMedia, 'data inside condition')
        socket.emit('sendMessage', {
          message: '',
          room_id: roomId,
          from_admin: true,
          media: uploadMedia,
          token: authToken,
          type: 'text',
        })
      }
    } catch (e) {
      console.log(e)
    } finally {
      closeUploadModal()
    }
  }

  // send text message to server
  const handleSendMessage = (e: any) => {
    e.preventDefault()
    if (message && message !== '') {
      console.log(message, 'sent')
      socket.emit('sendMessage', {
        message,
        room_id: roomId,
        from_admin: true,
        media: [],
        type: 'text',
        token: authToken,
      })
      setMessage('')
    }
  }

  // open the image in enlarge form
  const handleImageClick = (image: any) => {
    console.log('image', image)
    setModalImage(image)
    setModalOpen(true)
  }

  // close the image that is enlarged
  const handleCloseModal = () => {
    setModalOpen(false)
    setModalImage(null)
  }

  // scroll chats ,essage to bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault() // Prevent the default action (new line in the textarea)
      handleSendMessage(e)
    }
  }

  const showUploadModal = async () => {
    setUploadModal(true)
  }

  const closeUploadModal = async () => {
    setUploadModal(false)
  }

  const callChatListData = async () => {
    try {
      setRefreshing(true)
      let chatUsersList = await getChatUserListData()

      if (chatUsersList && chatUsersList.data) {
        await dispatch(chatsredux.actions.setChatsLoading(true))
        // once chats data set then loading will automatically off using saga middleware
        await dispatch(chatsredux.actions.setChatsData(chatUsersList.data))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setRefreshing(false)
    }
  }

  return (
    <>
      <div
        className='d-flex bg-white'
        style={{height: '80vh', overflowY: 'hidden', position: 'relative'}}
      >
        <div className='w-25 border-end' style={{overflowY: 'auto'}}>
          <div className='m-4'>
            <input
              type='text'
              placeholder='Search by username or phone or email...'
              className='form-control'
            />
          </div>
          <PullToRefresh onRefresh={callChatListData}>
            <ul className='list-unstyled'>
              {Array.isArray(chats) &&
                chats.map((chat: any, index: any) => (
                  <li
                    key={index}
                    // className='d-flex align-items-center justify-content-between mb-4 position-relative'
                    className={`d-flex align-items-center justify-content-between p-4 position-relative ${
                      activeChat && activeChat.data && activeChat.data.room_id === chat.data.room_id
                        ? 'bg-light'
                        : ''
                    }`}
                    onClick={() => handleChatClick(chat)}
                    style={{cursor: 'pointer'}}
                  >
                    <div className='d-flex align-items-center'>
                      <img
                        width='40px'
                        height='40px'
                        src={
                          chat.user_data && chat.user_data.image
                            ? chat.user_data.image
                            : toAbsoluteUrl('/media/avatars/blank.png')
                        }
                        alt={chat.name}
                        className='rounded-circle me-3'
                      />
                      <div>
                        <div className='fw-bold'>
                          {chat.user_data && chat.user_data.first_name && chat.user_data.last_name
                            ? `${chat.user_data.first_name} ${chat.user_data.last_name}`
                            : chat.user_data && chat.user_data.first_name
                            ? chat.user_data.first_name
                            : 'Unknown'}
                          {chat.data && chat.data.admin_unread > 0 && roomId !== chat.data.room_id && (
                            <Badge
                              status='processing'
                              style={{
                                height: '12px',
                                width: '12px',
                                position: 'relative',
                                top: '-4px',
                                left: '5px',
                              }}
                            />
                          )}
                        </div>
                        <div className='text-muted text-truncate' style={{maxWidth: '85px'}}>
                          {chat.data && chat.data.inquiry
                            ? chat.data.inquiry.type
                              ? chat.data.inquiry.type
                              : ''
                            : ''}
                          {'-'}
                          {chat.data && chat.data.inquiry
                            ? chat.data.inquiry.title
                              ? chat.data.inquiry.title
                              : ''
                            : ''}
                        </div>
                        <div
                          className={`${
                            chat.data && chat.data.admin_unread > 0
                              ? 'text-dark fw-bold'
                              : 'text-muted'
                          }`}
                          style={{fontSize: '10px'}}
                        >
                          {chat.data && chat.data.last_message
                            ? chat.data.last_message
                              ? chat.data.last_message
                              : 'Start Chating'
                            : 'Start Chating'}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-column align-items-end justify-content-between'>
                      <div className='text-muted' style={{fontSize: '10px'}}>
                        {chat.data && chat.data.last_messageAt
                          ? moment(chat.data.last_messageAt).format('DD-MMM-YYYY')
                          : ''}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </PullToRefresh>
        </div>
        <div
          className={`w-75 p-4 position-relative pt-0 pb-0 ${
            activeChat ? '' : 'd-none d-md-block'
          }`}
          style={{position: 'relative'}}
        >
          {activeChat ? (
            <>
              {console.log(activeChat, 'active')}
              {/* User Name and Active Status */}
              <div
                className='border-bottom pb-4 mb-4'
                style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1}}
              >
                <div className='d-flex align-items-center justify-content-between p-4'>
                  <div className='d-flex align-items-center'>
                    <img
                      width='30px'
                      height='30px'
                      src={toAbsoluteUrl('/media/avatars/blank.png')}
                      alt={'Image'}
                      className='rounded-circle me-3'
                    />
                    <div>
                      <div className='fw-bold fs-5'>
                        {activeChat.user_data &&
                        activeChat.user_data.first_name &&
                        activeChat.user_data.last_name
                          ? `${activeChat.user_data.first_name} ${activeChat.user_data.last_name}`
                          : activeChat.user_data.first_name
                          ? activeChat.user_data.first_name
                          : 'Unknown'}
                      </div>
                      <div className='text-success fw-bold'>
                        {activeChat.data && activeChat.data.inquiry
                          ? activeChat.data.inquiry.type
                            ? activeChat.data.inquiry.type
                            : ''
                          : ''}
                        {'-'}
                        {activeChat.data && activeChat.data.inquiry
                          ? activeChat.data.inquiry.title
                            ? activeChat.data.inquiry.title
                            : ''
                          : ''}
                      </div>
                    </div>
                  </div>

                  <div className='cursor-pointer' onClick={() => closeActiveChat()}>
                    <CloseOutlined />
                  </div>
                </div>
                <div>
                  {activeChat.data.inquiry.type === 'jewellery' && (
                    <div className='d-flex gap-3 align-items-baseline'>
                      <p className='text-primary fw-bold ms-3'>
                        Variant Price: $<span id='variantPrice'>{variantPrice}</span>
                      </p>
                      <button onClick={showPreviewModal} className='btn btn-primary ms-2 p-2'>
                        Update variant price
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {/* Chats */}
              <div
                ref={chatContainerRef}
                style={{paddingBottom: '45px', overflowY: 'auto', height: '30rem'}}
                className='d-flex flex-column gap-4 position-relative'
              >
                <Spin spinning={loadingMoreMessages} tip='Loading More Messages...'>
                  {messages &&
                    messages.map((message: any, index: any) => (
                      // {console.log(message)}
                      <div
                        key={index}
                        className={`d-flex ${message.from_admin ? 'flex-row-reverse' : ''}`}
                      >
                        {/* Render date tag if it's the first message or if date changes */}
                        {(index === 0 ||
                          moment(message.createdAt).format('YYYY-MM-DD') !==
                            moment(messages[index - 1].createdAt).format('YYYY-MM-DD')) && (
                          <div
                            className='text-center mt-3 mb-2 fw-bold position-absolute'
                            style={{transform: 'translate(-50%,50%)', margin: '50%'}}
                          >
                            <Tag color='blue'>
                              {moment(message.createdAt).calendar(null, {
                                sameDay: '[Today]',
                                lastDay: '[Yesterday]',
                                lastWeek: 'DD/MM/YYYY',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </Tag>
                          </div>
                        )}
                        {!message.from_admin && (
                          <img
                            width='40px'
                            height='40px'
                            src={toAbsoluteUrl('/media/avatars/blank.png')}
                            alt={'user_img'}
                            className='rounded-circle me-3'
                          />
                        )}
                        <div className='mw-75'>
                          <div className={`pt-3 fw-bold ${message.from_admin ? 'text-end' : ''}`}>
                            {!message.from_admin &&
                              (activeChat.user_data &&
                              activeChat.user_data.first_name &&
                              activeChat.user_data.last_name
                                ? `${activeChat.user_data.first_name} ${activeChat.user_data.last_name}`
                                : activeChat.user_data.first_name
                                ? activeChat.user_data.first_name
                                : 'Unknown')}
                            <span className='text-muted pl-1'>
                              {message.createdAt
                                ? moment.utc(message.createdAt).local().format('hh:mm A')
                                : ''}
                            </span>
                          </div>
                          {message.message ? (
                            <div
                              className={`p-3 rounded mt-1 ${
                                message.from_admin ? 'bg-primary text-white' : 'bg-light'
                              }`}
                            >
                              {message.message}
                            </div>
                          ) : (
                            message &&
                            message.media &&
                            Array.isArray(message.media) &&
                            message.media.length > 0 && (
                              <div
                                className={`p-3 rounded mt-1 ${
                                  message.from_admin ? 'bg-primary text-white' : 'bg-light'
                                }`}
                                style={{display: 'flex', flexWrap: 'wrap', gap: '10px'}}
                              >
                                {message.media.map((item: any, mediaIndex: number) => {
                                  const isType = item.type

                                  return isType == 'image' ? (
                                    <>
                                      <img
                                        key={mediaIndex}
                                        src={item.file_url}
                                        alt={`Image`}
                                        className='cursor-pointer'
                                        style={{
                                          width: '100px',
                                          height: '100px',
                                          objectFit: 'cover',
                                          borderRadius: '5px',
                                        }}
                                        onClick={() => handleImageClick(item.file_url)}
                                      />
                                    </>
                                  ) : isType == 'video' ? (
                                    <video
                                      key={mediaIndex}
                                      src={item.file_url}
                                      controls
                                      className='cursor-pointer'
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        objectFit: 'cover',
                                        borderRadius: '5px',
                                      }}
                                    />
                                  ) : isType == 'audio' ? (
                                    <audio
                                      key={mediaIndex}
                                      src={item.file_url}
                                      controls
                                      className='cursor-pointer'
                                    />
                                  ) : null
                                })}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                </Spin>
              </div>

              {/* Send Message */}
              <div
                className='d-flex align-items-center p-4 bg-white text-dark rounded border-top'
                style={{position: 'sticky', bottom: 0}}
              >
                {!isRecording && recording && !!recording.length ? (
                  recording.map((recUrl: any, index: any) => {
                    return (
                      <div className='flex-grow-1 d-flex align-items-center' key={index}>
                        <span>
                          <audio controls src={recUrl} />
                        </span>
                        <span style={{height: '40px'}}>
                          <Button
                            className='ml-2 rounded-circle bg-light'
                            style={{border: 'none', width: '40px', height: '100%'}}
                            icon={
                              <DeleteOutlined
                                shape='circle'
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  color: 'red',
                                }}
                              />
                            }
                            onClick={() => {
                              deleteRecording()
                            }}
                          />
                        </span>
                      </div>
                    )
                  })
                ) : !isRecording ? (
                  <textarea
                    rows={2}
                    placeholder='Type a message'
                    value={message}
                    className='flex-grow-1 p-2 border rounded bg-white text-dark form-control'
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                  />
                ) : (
                  <div className='flex-grow-1 fw-bold text-dark' style={{margin: '0 auto'}}>
                    Audio recording in progress
                  </div>
                )}
                <>
                  <div className='d-flex ms-2'>
                    {!isRecording && (
                      <div className='btn p-2'>
                        <Tooltip placement='bottom' title='Upload'>
                          <Button
                            style={{border: 'none'}}
                            icon={
                              <UploadOutlined
                                twoToneColor='#eb2f96'
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              />
                            }
                            onClick={() => {
                              showUploadModal()
                            }}
                          />
                        </Tooltip>
                      </div>
                    )}
                    {/* <div className='btn p-2'>
                      {!isRecording && recording && !!recording.length ? (
                        <Button
                          style={{border: 'none'}}
                          icon={
                            <SendOutlined
                              twoToneColor='#eb2f96'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            />
                          }
                          onClick={() => {
                            sendRecordingToServer()
                          }}
                        />
                      ) : !isRecording ? (
                        <Tooltip placement='bottom' title='Audio'>
                          <Button
                            style={{border: 'none'}}
                            icon={
                              <AudioOutlined
                                twoToneColor='#eb2f96'
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              />
                            }
                            onClick={() => {
                              handleAudioClick()
                            }}
                          />
                        </Tooltip>
                      ) : (
                        <Button
                          style={{border: 'none'}}
                          icon={
                            <PauseCircleOutlined
                              twoToneColor='#eb2f96'
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            />
                          }
                          onClick={() => {
                            handleAudioClick()
                          }}
                        />
                      )}
                    </div> */}
                  </div>
                  {!isRecording && (
                    <button
                      onClick={(e) => handleSendMessage(e)}
                      className='btn btn-primary ms-2 p-2'
                    >
                      Send
                    </button>
                  )}
                </>
              </div>
              {showScrollDownButton && (
                <Tooltip placement='leftTop' title='Scroll Down'>
                  <DownOutlined
                    className='bg-primary'
                    onClick={() => {
                      scrollToBottom()
                    }}
                    style={{
                      position: 'absolute',
                      bottom: '100px', // Adjust position as needed
                      right: '32px', // Adjust position as needed
                      zIndex: 1000,
                      fontSize: '16px',
                      border: '1px solid white',
                      borderRadius: '50%',
                      padding: '8px',
                      color: 'white',
                    }}
                  />
                </Tooltip>
              )}
            </>
          ) : (
            <div className='h-100 d-none d-md-flex flex-column align-items-center justify-content-center text-center'>
              <img
                alt='Image'
                className='h-125px mb-3'
                src={toAbsoluteUrl('/media/logos/DC-Logo.svg')}
              />
              <div className='w-50 text-dark fw-bold text-left'>
                Click on any chat and begin solving their issues instantly. Engage with users
                directly and provide swift support. Your assistance makes a difference—start
                chatting now!
              </div>
            </div>
          )}
        </div>
      </div>
      <CommanModal
        show={uploadModal ? true : false}
        handleClose={() => {
          closeUploadModal()
        }}
        title={'Upload Files'}
        submitText={'Save Changes'}
      >
        <div>
          {uploadModal && (
            <UploadMediaModal
              socket={socket}
              handleCancel={() => {
                closeUploadModal()
              }}
              onSubmitMedia={(mediaData: any) => {
                handleMediaUploadFiles(mediaData)
              }}
            />
          )}
        </div>
      </CommanModal>
      {/* //my */}
      <CommanModal
        show={variantModel ? true : false}
        handleClose={() => {
          closeEditModal()
        }}
        title={'Update variant price'}
        submitText={'Save Changes'}
      >
        <div>
          {variantModel && (
            <UpdateVariantPriceForm
              // socket={socket}
              Id={activeChat.data.room_id}
              variantPrice={variantPrice}
              handleCancel={() => {
                closeEditModal()
              }}
              // handleCancel={() => {
              //   closeEditModal()
              // }}
            />
          )}
        </div>
      </CommanModal>
      {modalOpen && (
        <div
          className='modal-overlay'
          onClick={handleCloseModal}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <img
            src={modalImage}
            alt='modal-content'
            onClick={(e) => e.stopPropagation()}
            style={{maxHeight: '90%', maxWidth: '90%'}}
          />
        </div>
      )}
    </>
  )
}
