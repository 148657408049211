/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ChangePassword} from './ChangePassword'
import {Tabs} from 'antd'
import {UserModel} from '../../../modules/auth/models/UserModel'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {ChangeEmail} from './ChangeEmail'
import {AdminBillingDetails} from './AdminBillingDetails'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {KycDocumentForm} from '../../kyc/containers/KycDocumentForm'
import {KycUpload} from './KycUpload'
const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

const {TabPane} = Tabs

const DisplaySetting: FC = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const {role, permission} = user
  return (
    <div>
      <div style={{marginBottom: '30px'}}>
        <ChangeEmail />
        <ChangePassword />
        <KycUpload /> {role === 'super_admin' && <AdminBillingDetails />}
      </div>
      {role === 'vendor'&& <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>FTP Details</h3>
        </div>
        <div className='card-body'>
          <div className='d-flex flex-stack mb-2'>
            <div className='mb-3'>
              <label className='form-label text-dark fw-bold d-block mb-2' htmlFor='ftpUsername'>
                FTP Username : 
                <span id='ftpUsername' className='text-muted fs-6'>
                  {' '}{user.ftp_username || 'N/A'}
                </span>
              </label>
            </div>
          </div>
          <div className='d-flex flex-stack mb-2'>
            <div className='mb-3'>
              <label className='form-label text-dark fw-bold d-block mb-2' htmlFor='ftpUsername'>
                FTP Password : 
                <span id='ftpUsername' className='text-muted fs-6'>
                  {' '}{user.ftp_password || 'N/A'}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}

export {DisplaySetting}
