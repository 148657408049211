/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getInventoryData, getSearchInventoryData} from '../redux/InventoryCRUD'
import {useDispatch} from 'react-redux'
import * as inventory from '../redux/InventoryRedux'
import ListInventoryData from './ListInventoryData'
import ListSearchForm from './ListSearchForm'
import {Tabs} from 'antd'
import styled from 'styled-components'
import ListAdminInventoryData from './ListAdminInventoryData'
import {Spin} from 'antd'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {getSearchVendorsData} from '../../vendors/redux/VendorsCRUD'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import ListVendorsData from '../../vendors/containers/ListVendorsData'
import {CommanModal} from '../../../modules/comman/components/ModalComponent'
import ListVendorData from './ListVendorData'
import useDebounce from './useDebounce' // Adjust path accordingly

const {TabPane} = Tabs

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav .ant-tabs-tab:nth-child(2) {
    margin-left: 0px !important;
  }

  .ant-tabs-nav .ant-tabs-tab {
    border-radius: 0px !important;
  }

  .ant-tabs-content-holder {
    border-width: 1px;
    border-color: #f0f0f0;
    border-style: solid;
    border-top-style: none;
    padding: 1rem;
  }
`

const ListPage: FC = () => {
  const [tabKey, setTabKey] = useState('1')
  const [searchText, setSearchText] = useState('')
  const [vendorData, setVendorData] = useState(null)

  const [selectedVendorData, setSelectedVendorData] = useState<any[]>([])

  const debouncedSearchText = useDebounce(searchText, 500) // Debounce with 500ms delay

  const dispatch = useDispatch()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const [searchPagination, setSearchPagination] = useState({
    skip: 0,
    limit: 10,
    filterObject: {},
    key: '1',
  })
  const [key, setKey] = useState('1')
  const [isLoading, setIsLoading] = useState(false)

  const {role, permission} = user

  const onSearchChange = async (value: string) => {
    try {
      setSearchText(value)
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const callInventoryData = async () => {
    try {
      let inventoryData = await getInventoryData(
        searchPagination.skip,
        searchPagination.limit,
        key == '2' ? true : false,
        '',
        selectedVendorData?.[0]?.id,
        searchPagination?.filterObject
      )
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        // once inventory data set then loading will automatically off using saga middleware
        await dispatch(inventory.actions.setInventoryData(inventoryData.data))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    if (debouncedSearchText) {
      listVendorsSearchData(0, 1000)
    } else {
      setSelectedVendorData([])
      // callInventoryData()
    }
  }, [debouncedSearchText])

  useEffect(() => {
    setIsLoading(true)

    console.log({selectedVendorData})
    if (selectedVendorData.length) {
      const vendor = `${selectedVendorData?.[0]?.first_name} ${selectedVendorData?.[0]?.last_name}`
      // setSearchText(vendor)
      // callInventoryData()
    }
    callInventoryData()

    setIsLoading(false)
  }, [selectedVendorData])

  const listVendorsSearchData = async (start: number, end: any) => {
    try {
      let vendorsData = await getSearchVendorsData(start, end, debouncedSearchText)
      if (vendorsData.data) {
        // console.log({data: vendorsData.data})
        setVendorData(vendorsData.data)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
    }
  }

  // useEffect(() => {
  //   callInventoryData()
  // }, [])

  const searchInventoryData = async (values: any, debouncedSearchText: any) => {
    try {
      setSearchPagination((prevState) => ({
        ...prevState,
        filterObject: values,
      }))

      await dispatch(inventory.actions.setInventoryLoading(true))
      let inventoryData = await getSearchInventoryData(
        searchPagination.skip,
        searchPagination.limit,
        values,
        key == '2' ? true : false,
        debouncedSearchText
      )
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryData(inventoryData.data))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.count))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await dispatch(inventory.actions.setInventoryLoading(false))
      console.log('finally false')
    }
  }

  const callback = async (selectedKey: any) => {
    try {
      setKey(selectedKey)
      await callStockDataOnTabChange(selectedKey)
    } catch (e) {
      console.log(e, 'error')
    }
  }

  const callStockDataOnTabChange = async (changedKey: any, status?: any) => {
    try {
      setIsLoading(true)
      let inventoryData = await getInventoryData(
        searchPagination.skip,
        searchPagination.limit,
        changedKey == '2' ? true : false,
        status,
        selectedVendorData?.[0]?.id,
        searchPagination.filterObject
      )
      if (inventoryData.data) {
        await dispatch(inventory.actions.setInventoryLoading(true))
        await dispatch(inventory.actions.setInventoryData(inventoryData.data))
        await dispatch(inventory.actions.setInventoryDataTotal(inventoryData.count))
        await dispatch(inventory.actions.setInventoryLoading(false))
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      setIsLoading(false)
    }
  }
  const onChangeTab = (key: string) => {
    console.log(key, typeof key, 'key555')
    setTabKey(key)
    if (key === '4') {
      callStockDataOnTabChange(1, 'RETURNED')
    } else if (key === '3') {
      callStockDataOnTabChange(1, 'SOLD')
    } else if (key === '2') {
      callStockDataOnTabChange(1, 'HOLD')
    } else {
      callStockDataOnTabChange(1, 'AVAILABLE')
    }
  }

  const onChangeAdminTab = (key: string) => {
    console.log(key, typeof key, 'key555')
    setTabKey(key)
    if (key === '4') {
      callStockDataOnTabChange(2, 'RETURNED')
    } else if (key === '3') {
      callStockDataOnTabChange(2, 'SOLD')
    } else if (key === '2') {
      callStockDataOnTabChange(2, 'HOLD')
    } else {
      callStockDataOnTabChange(2, 'AVAILABLE')
    }
  }
  useEffect(() => {
    if (!Object.keys(searchPagination.filterObject).length) {
      callInventoryData()
    }
  }, [searchPagination])

  return (
    <div>
      <ListSearchForm
        onSubmit={searchInventoryData}
        onClearSearch={() => {
          setSearchPagination((prevState) => ({
            ...prevState,
            filterObject: {},
          }))
        }}
      />
      <StyledTabs className='robert-font-medium' type='card' activeKey={key} onChange={callback}>
        {' '}
        <TabPane tab='Vendor Stocks' key='1'>
          <Spin spinning={isLoading} tip='Loading...'>
            {key === '1' && (
              <Spin spinning={isLoading} tip='Loading...'>
                <div className='card-container'>
                  {role !== 'vendor' && (
                    <div className='d-flex align-items-center gap-4'>
                      <div
                        className='bg-white p-4 d-flex align-items-center'
                        style={{width: '30%'}}
                      >
                        {' '}
                        <h3 className='card card-title table-card-title' style={{width: '100%'}}>
                          <InputSearchText
                            onChange={(value: string) => {
                              onSearchChange(value)
                            }}
                            placeholder='Search Vendors'
                            input={{
                              value: searchText,
                            }}
                          />
                        </h3>
                      </div>

                      {selectedVendorData &&
                        selectedVendorData.length > 0 &&
                        selectedVendorData[0]?.first_name && (
                          <div className='card'>
                            <div
                              className='card-body d-flex align-items-center gap-4'
                              style={{padding: '2rem'}}
                            >
                              {/* <h5 className='card-title'>Selected Vendor :</h5> */}
                              <p className='card-text mb-0'>
                                <strong>Vendor Name : </strong>{' '}
                                {`${selectedVendorData[0]?.first_name} ${selectedVendorData[0]?.last_name}`}
                              </p>
                              <p className='card-text'>
                                <strong>Email : </strong> {selectedVendorData[0]?.email}
                              </p>
                            </div>
                          </div>
                        )}
                    </div>
                  )}

                  <Tabs defaultActiveKey='1' onChange={onChangeTab} type='card'>
                    <TabPane tab='Available' key='1'>
                      <ListInventoryData
                        tabkey='AVAILABLE'
                        vendor_id={selectedVendorData?.[0]?.id}
                      />
                    </TabPane>
                    <TabPane tab='Hold' key='2'>
                      <ListInventoryData tabkey='HOLD' vendor_id={selectedVendorData?.[0]?.id} />
                    </TabPane>
                    <TabPane tab='Sold' key='3'>
                      <ListInventoryData tabkey='SOLD' vendor_id={selectedVendorData?.[0]?.id} />
                    </TabPane>
                    <TabPane tab='Return' key='4'>
                      <ListInventoryData
                        tabkey='RETURNED'
                        vendor_id={selectedVendorData?.[0]?.id}
                      />
                    </TabPane>
                  </Tabs>
                  <div className='card-body'>
                    <div>
                      {/* <ListVendorsData /> */}
                      <CommanModal
                        show={vendorData ? true : false}
                        handleClose={() => {
                          // this.closeEditModal()
                          setVendorData(null)
                        }}
                        title={'vendor list'}
                        submitText={'Save Changes'}
                        isScrollable={true}
                      >
                        <ListVendorData
                          vendorData={vendorData}
                          setSelectedVendorData={setSelectedVendorData}
                          selectedVendorData={selectedVendorData}
                          handleCancel={() => {
                            setVendorData(null)
                          }}
                        />
                        {/* <ListTable
                          rowKey={(record: any) => record._id}
                          onChange={handleVendorTableChange}
                          column={columns}
                          onPaginationChange={handlePaginationChange}
                          paginationConfig={{
                            total: 100,
                            showSizeChanger: true,
                            pageSize: vendorPagination.pageSize,
                            current: vendorPagination.current,
                          }}
                          isLoading={false}
                          dataSource={vendorData}
                          counter={counter}
                          tempCounter={tempCounter}
                          isScroll={true}
                          scrollWidth={role === 'super_admin' ? 1350 : 1200}
                        /> */}
                      </CommanModal>
                    </div>
                  </div>
                </div>
              </Spin>
            )}
          </Spin>
        </TabPane>
        {role !== 'vendor' && (
          <TabPane tab='Admin Stocks' key='2'>
            <Spin spinning={isLoading} tip='Loading...'>
              {/* {key === '2' && <ListAdminInventoryData />} */}
              <Spin spinning={isLoading} tip='Loading...'>
                <div className='card-container'>
                  <Tabs defaultActiveKey='1' onChange={onChangeAdminTab} type='card'>
                    <TabPane tab='Available' key='1'>
                      <ListAdminInventoryData tabkey='AVAILABLE' />
                    </TabPane>
                    <TabPane tab='Hold' key='2'>
                      <ListAdminInventoryData tabkey='HOLD' />
                    </TabPane>
                    <TabPane tab='Sold' key='3'>
                      <ListAdminInventoryData tabkey='SOLD' />
                    </TabPane>
                    <TabPane tab='Return' key='4'>
                      <ListAdminInventoryData tabkey='RETURN' />
                    </TabPane>
                  </Tabs>
                </div>
              </Spin>
            </Spin>
          </TabPane>
        )}
      </StyledTabs>
    </div>
  )
}

const ListWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'List',
            path: '/inventory/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.INVENTORY'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {ListWrapper}
